import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, tap } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ContactService {
  /**
   * Constructor
   */
  constructor(private _httpClient: HttpClient) {}

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Submit the contact form to the API backend
   */
  createContactMessage(
    name: string,
    emailAddress: string,
    contactMessage: string
  ): Observable<any[]> {
    return this._httpClient
      .post<any>('/api/contact/v1/messages', {
        name,
        emailAddress,
        contactMessage,
      })
      .pipe(
        tap((response: any) => {
          return response;
        })
      );
  }
}
